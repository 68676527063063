<template>
  <button
    type="submit"
    class="btn inline-flex items-center justify-center w-full px-6 py-4 font-semibold text-white transition-all duration-200 bg-gray-900 rounded-md hover:bg-gray-600 focus:bg-gray-600"
    :class="{ 'w-full': block }"
    :disabled="disabled"
  >
    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
      <slot name="icon" />
    </span>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    block: Boolean,
    boolean:Boolean
  },
};
</script>

<style></style>
