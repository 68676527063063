<template>
  <div class="bg-gray-200">
    <router-view />
  </div>
</template>

<script>
import { onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      store.dispatch("AUTHENTICATE", {});
      store.dispatch("FETCH_DATA_BY_IP");
      store.dispatch("GET_MEMBERSHIPS");
      window.addEventListener("Adbox/Checkout.Coupon.Applied", (eventData) => {
        store.commit("ADD_COUPON", {
          code: eventData.detail.coupon.coupon_code,
          total: eventData.detail.checkout.prices.customer.total,
        });
      });

      window.addEventListener("Adbox/Checkout.Coupon.Remove", () => {
        store.commit("REMOVE_COUPON");
      });
    });

    watch(route, () => {
      store.dispatch("SEARCH_PLAN", route.query);
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,200;1,300;1,400&display=swap");

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
