<template>
  <div class="min-h-cover h-full">
    <div class="flex items-center justify-center bg-gray-50 py-0 my-0">
      <div class="w-full  h-screen  h-full">
        <section class="bg-white  h-screen">
          <div class="grid grid-cols-1 lg:grid-cols-2  h-screen">
            <div
              class="relative flex items-end px-4 pb-10 pt-60 sm:pb-16 md:justify-left lg:pb-24 bg-gray-50 sm:px-6 lg:px-12"
            >
              <div class="absolute inset-0">
                <img
                  class="object-cover object-top w-full h-full"
                  src="@/assets/signin.png"
                  alt=""
                />
              </div>
              <div
                class="absolute inset-0 bg-gradient-to-t from-black to-transparent"
              ></div>

              <div class="relative">
                <div
                  class="w-full max-w-xl xl:w-full xl:mx-auto xl:pr-24 xl:max-w-xl"
                >
                  <h3 class="text-4xl font-bold text-white text-left">
                    Welcome back! <br class="hidden xl:block" />
                  </h3>
                  <ul
                    class="grid grid-cols-1 mt-10 sm:grid-cols-2 gap-x-8 gap-y-4"
                  >
                    <li class="flex items-center space-x-3">
                      <div
                        class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full"
                      >
                        <svg
                          class="w-3.5 h-3.5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <span class="font-medium text-white text-left">
                        Let's reduce that CPA
                      </span>
                    </li>
                    <li class="flex items-center space-x-3">
                      <div
                        class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full"
                      >
                        <svg
                          class="w-3.5 h-3.5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <span class="font-medium text-white">
                        and boost your sales!
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="flex items-center justify-center px-4 py-10 bg-white sm:px-6 lg:px-8 sm:py-16 lg:py-24"
            >
              <div class="xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto">
                <h2
                  class="text-3xl font-bold leading-tight text-black sm:text-4xl text-left"
                >
                  Sign in to Adbox
                </h2>
                <p class="mt-2 text-base text-gray-600 text-left">
                  Don’t have an account?
                  <a
                    href="signup"
                    title=""
                    class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    >Create an account</a
                  >
                </p>

                <form class="form mt-8" v-on:submit.prevent="signIn">
                  <div class="space-y-5">
                    <div class="text-left">
                      <label
                        for=""
                        class="items-left text-base font-medium text-gray-900"
                      >
                        Email address
                      </label>
                      <div
                        class="mt-2.5 relative text-gray-400 focus-within:text-gray-600"
                      >
                        <div
                          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                        >
                          <svg
                            class="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                            />
                          </svg>
                        </div>

                        <input
                          type="email"
                          name=""
                          id=""
                          placeholder="Email"
                          v-model="email"
                          class="block w-full mt-2 py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                        />
                      </div>
                    </div>

                    <div>
                      <div class="flex items-center justify-between">
                        <label
                          for=""
                          class="text-base font-medium text-gray-900"
                        >
                          Password
                        </label>
                        <router-link
                          to="/forgot-password/send-email"
                          class="text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                        >
                          Forgot your password?
                        </router-link>
                      </div>
                      <div
                        class="w-full flex justify-center items-center py-2"
                        v-if="error"
                      >
                        <div
                          class="w-5 h-5 bg-red-600 grid place-content-center mr-2 rounded-md"
                        >
                          <Icon name="Close" class="w-3 h-3 text-white" />
                        </div>
                        <p
                          v-if="
                            error === 'User does not exist. Please signup here'
                          "
                          class="text-red-600 font-medium leading-none"
                        >
                          User does not exist.
                          <a
                            v-if="
                              error ===
                                'User does not exist. Please signup here'
                            "
                            href="signup"
                            title=""
                            class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline hover:underline transition ease-in-out duration-150"
                            >Please signup here</a
                          >
                        </p>
                        <p v-else class="text-red-600 font-medium leading-none">
                          {{ error }}
                        </p>
                      </div>
                      <div
                        class="mt-2.5 relative text-gray-400 focus-within:text-gray-600"
                      >
                        <div
                          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                        >
                          <svg
                            class="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                            />
                          </svg>
                        </div>

                        <input
                          type="password"
                          name=""
                          id=""
                          v-model="password"
                          placeholder="Password"
                          class="block w-full mt-2 py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                        />
                      </div>
                    </div>

                    <div>
                      <ColoredButton block class="btn">
                        Access Adbox
                      </ColoredButton>
                    </div>
                  </div>
                </form>

                <div class="mt-3 space-y-3"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- 
      <form class="form mt-8" v-on:submit.prevent="signIn">
        <input type="hidden" name="remember" value="true" />
        <div>
          <div class="mb-4">
            <Label for="email" class="mb-2">Email address</Label>
            <Input
              id="email"
              aria-label="Email address"
              name="email"
              type="text"
              required
              placeholder="Email address"
              v-model="email"
              class="mt-6"
            />
          </div>
          <div>
            <Label for="password">Password</Label>
            <Input
              id="password"
              aria-label="Password"
              name="password"
              type="password"
              required
              placeholder="Password"
              v-model="password"
            />
          </div>
        </div>

        <div class="w-full flex justify-center items-center py-2" v-if="error">
          <div
            class="w-5 h-5 bg-red-600 grid place-content-center mr-2 rounded-md"
          >
            <Icon name="Close" class="w-3 h-3 text-white" />
          </div>
          <p class="text-red-600 font-medium leading-none">
            {{ error }}
          </p>
        </div>

        <div class="mt-6 flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember_me"
              type="checkbox"
              class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
            />
            <label
              for="remember_me"
              class="ml-2 block text-sm leading-5 text-gray-900"
            >
              Remember me
            </label>
          </div>

          <div class="text-sm leading-5">
            <router-link
              to="/forgot-password/send-email"
              class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Forgot your password?
            </router-link>
          </div>
        </div>

        <div class="mt-6">
          <Button block>
            <template v-slot:icon>
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                fill="#ffffff"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </template>
            Sign in
          </Button>
        </div>
      </form> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import ColoredButton from "@/components/UI/ColoredButton";

// import Button from "@/components/UI/Button";
// import Input from "@/components/UI/Input";
// import Label from "@/components/UI/Label";
// import Icon from "@/components/UI/Icon";

export default {
  name: "SignIn",
  components: { ColoredButton },
  setup() {
    const email = ref("");
    const password = ref("");
    const error = ref("");

    const store = useStore();

    const signIn = async () => {
      const { success, data } = await store.dispatch("SIGN_IN", {
        email: email.value,
        password: password.value,
      });
      if (!success) {
        error.value = data.data.data.message;
      }

      //call profitwill
      window.profitwell("start", { user_email: email.value });
    };
    const url = process.env.VUE_APP_ADBOX_FRONT_URL_FREE;

    return { email, password, error, signIn, url };
  },
};
</script>
<style scoped>
.btn {
  background-image: linear-gradient(90deg, #c026d3, #2563eb);
}
.btn:hover :focus {
  opacity: 80;
}
.title {
  display: flex;
  justify-content: left;
  letter-spacing: -2px;
}
.title__box {
  letter-spacing: -2px;
  color: #333;
}
.title__ad {
  color: #3652d9;
}
</style>
