import { createStore } from 'vuex';
import router from '@/router';
import axios from 'axios';
import Cookies from 'js-cookie';
import sleep from '@/utils/sleep.js';

const ADBOX_API_URL = process.env.VUE_APP_ADBOX_API_URL;

export default createStore({
  state: {
    ipData: null,
    plans: null,
    currentAccount: null,
    currentPlan: null,
    coupon: null,
    loadingAfterPayment: false,
    signUpResponse: null,
    pendingPay: false,
    errorLogin: false,
  },
  mutations: {
    SET_IP_DATA(state, value) {
      state.ipData = value;
    },
    SET_MEMBERSHIPS(state, value) {
      if (value.length) {
        state.plans = value.map((plan) => {
          plan.name;
          plan.period,
            plan.price,
            plan.use_discount,
            plan.discounted_price,
            (plan.features = JSON.parse(plan.features));
          plan.extras,
            plan.paddle_plan_id,
            (plan.paddle = {
              id: plan.paddle_plan_id,
              vendor: 26610,
            });
          return plan;
        });
      } else {
        state.plans = {
          name: value.name,
          period: value.period,
          price: value.price,
          use_discount: value.use_discount,
          discounted_price: value.discounted_price,
          features: JSON.parse(value.features),
          extras: value.extras,
          paddle_plan_id: value.paddle_plan_id,
          paddle: {
            id: value.paddle_plan_id,
            vendor: 26610,
          },
        };
      }
    },
    SET_CURRENT_PLAN(state, value) {
      state.currentPlan = value;
    },
    SET_CURRENT_ACCOUNT(state, value) {
      state.currentAccount = value;
    },
    ADD_COUPON(state, value) {
      let object = value;
      object.total = parseInt(object.total);
      object.discount_price = state.currentPlan.price - object.total;
      object.discount_percent = (object.discount_price * 100) / state.currentPlan.price;
      state.coupon = value;
    },
    REMOVE_COUPON(state) {
      state.coupon = null;
    },
    SET_LOADING_AFTER_PAYMENT(state, value) {
      state.loadingAfterPayment = value;
    },
    SET_SIGN_UP_RESPONSE(state, value) {
      state.signUpResponse = value;
    },
    SET_PENDING_PAY(state, value) {
      state.pendingPay = value;
    },
    SET_ERROR_LOGIN(state, value) {
      state.errorLogin = value;
    },
  },
  actions: {
    async FETCH_DATA_BY_IP({ commit }) {
      try {
        const res = await axios({
          method: 'GET',
          url: 'https://api.ipify.org?format=json',
        });
        console.log({ res });
        const { data } = await axios({
          method: 'GET',
          url: `https://geo.ipify.org/api/v1?apiKey=at_Z516k321qx1tbPDgrX5xV0KQhekl5&ipAddress=${res.data.ip}`,
        });
        commit('SET_IP_DATA', data);
      } catch (error) {
        console.error("ipify don't working");
      }
    },
    async GET_MEMBERSHIPS({ commit }, period) {
      try {
        const response = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_ADBOX_API_URL}/memberships`,
          headers: { 'Cache-Control': 'no-cache' },
          params: period,
        });
        commit('SET_MEMBERSHIPS', response.data.memberships);
        return { success: true, data: response.data.memberships };
      } catch (error) {
        return { success: false, data: error.response };
      }
    },
    SEARCH_PLAN({ state, commit }, value) {
      if (!Object.prototype.hasOwnProperty.call(value, 'plan')) return null;
      if (state.plans) {
        commit(
          'SET_CURRENT_PLAN',
          state.plans.find((item) => item.name.toLowerCase() === value.plan)
        );
      }
    },
    async SIGN_IN({ dispatch }, { email, password }) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${ADBOX_API_URL}/signin`,
          data: {
            email,
            password,
          },
        });

        dispatch('AUTHENTICATE', { response });
        return { success: true, data: response.data.data };
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async SIGN_UP({ commit }, form) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${ADBOX_API_URL}/signup`,
          data: form,
        });
        commit('SET_SIGN_UP_RESPONSE', response);
        return { success: true, data: response.data.data };
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async SIGN_UP_FREE({ commit, dispatch }, form) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${ADBOX_API_URL}/signup_free`,
          data: form,
        });
        if (response && response.data) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'free_signup',
            userId: response.data.data.user.id,
            type: response.data.data.user.type,
          });
        }
        commit('SET_SIGN_UP_RESPONSE', response);
        dispatch('AUTHENTICATE', { response: response });
        return { success: true, data: response.data.data };
      } catch (err) {
        return { success: false, data: err.response };
      }
    },
    async WELCOME({ dispatch }, form) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${ADBOX_API_URL}/welcome`,
          data: form,
        });
        dispatch('AUTHENTICATE', { response });
        return { success: true, data: response.data.data };
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    CHANGE_PLAN({ state, commit, dispatch }, item) {
      commit('SET_CURRENT_PLAN', item);
      commit('REMOVE_COUPON');
      if (state.currentAccount) {
        dispatch('SET_PADDLE');
      }
    },
    async SET_PADDLE({ state, dispatch }) {
      console.log("currentPlan", state.currentPlan)
      if (!state.currentPlan && !state.currentPlan.name === 'free') return null;
      console.log("widnow testing", window)
      console.log("widnow testing", window.rewardful)
      const referral = window.rewardful && window.rewardful.referral;
      console.log("referal", referral)
      // console.log("currentAccount and email", state.currentAccount)
      window.Paddle.Checkout.open({
        //product: state.currentPlan.paddle.id,
        product: '636842',
        passthrough: JSON.stringify({ rewardful: { referral: referral } }),
        type: state.currentAccount.type,
        email: state.currentAccount.email,
        country: '',
        allowQuantity: false,
        disableLogout: true,
        frameTarget: 'checkout-container', // The className of your checkout <div>
        frameInitialHeight: 416,
        // frameStyle:
        //   "width: 100%; min-width: 280px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 495px
        successCallback: (data) => {
          console.log('success_callback', data);
          dispatch('PADDLE_CHECKOUT_SUCCESS', data);
        },
      });
    },
    async PADDLE_CHECKOUT_SUCCESS({ state, commit, dispatch }, data) {
      try {
        console.log("paddle success", JSON.stringify(data))
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            currency: data.checkout.prices.customer.currency,
            transaction_id: data.checkout.id,
            value: Number(data.checkout.prices.customer.total),
            coupon: data.checkout.coupon.coupon_code,
            tax: Number(data.checkout.prices.customer.total_tax),
            items: [
              {
                item_id: data.product.id.toString(),
                item_name: data.product.name,
                affiliation: 'Adbox',
                index: 1,
                item_brand: 'Adbox Membership',
                price: Number(data.checkout.prices.customer.total),
                quantity: data.product.quantity,
              },
            ],
          },
        });
      } catch (error) {
        console.log('PADDLE_CHECKOUT_SUCCESS ERROR:', error);
      }
      router.push('/thank-you');
      await sleep(5000);
      const { success } = await dispatch('SIGN_IN', {
        email: state.currentAccount.email,
        password: state.currentAccount.password,
      });
      if (!success) {
        commit('SET_PENDING_PAY', true);
        await sleep(5000);
        const { success } = await dispatch('SIGN_IN', {
          email: state.currentAccount.email,
          password: state.currentAccount.password,
        });
        if (!success) {
          commit('SET_ERROR_LOGIN', true);
        }
      }
    },
    AUTHENTICATE(_, { response = null, redirect = true }) {
      if (!response) return null;
      Cookies.set('adbox_auth_token', response.data.data.access_token, {
        domain: process.env.VUE_APP_ADBOX_DOMAIN,
        expires: new Date(response.data.data.expires_in),
      });
      const url = window.location.search;
      const urlParams = new URLSearchParams(url);
      const page = urlParams.get('page');

      Cookies.set('adbox_auth_role', response.data.data.user.role, {
        domain: process.env.VUE_APP_ADBOX_DOMAIN,
        expires: new Date(response.data.data.expires_in),
      });
      if (!redirect) return null;
      if (page) {
        window.location.href = process.env.VUE_APP_ADBOX_APP_FRONT_URL_PRICING;
      } else {
        console.log('fffff', response.data.data.user.role);

        switch (response.data.data.user.role) {
          case 'lifetime':
            window.location.href = process.env.VUE_APP_ADBOX_APP_FRONT_URL;
            break;
          case 'admin':
            window.location.href = process.env.VUE_APP_ADBOX_ADMIN_FRONT_URL;
            break;
          case 'super':
            window.location.href = process.env.VUE_APP_ADBOX_ADMIN_FRONT_URL;
            break;
          case 'default':
            window.location.href = process.env.VUE_APP_ADBOX_APP_FRONT_URL;
            break;
          case 'annual':
            window.location.href = process.env.VUE_APP_ADBOX_APP_FRONT_URL;
            break;
          case 'free':
            window.location.href = process.env.VUE_APP_ADBOX_APP_FRONT_URL;
            break;
          case 'monthly':
            window.location.href = process.env.VUE_APP_ADBOX_APP_FRONT_URL;
            break;
        }
      }
    },
  },
  modules: {},
});
