/**
 * 
 * @param {number} time an time in milliseconds
 */
export default (time = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, time)
    })
}